
import mixins from "vue-typed-mixins";
import { BModal, BIcon } from "bootstrap-vue";
import CustomValidation from "../../mixins/CustomValidation";
import Common from "../../mixins/Common";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import store from "@/store";
import { mapGetters, mapMutations } from "vuex";
import { object, string, boolean } from "yup";
import { IReCaptchaVue } from "../../interfaces/IReCaptchaVue";
import { PatientRegisterViewModel } from "@/interfaces/PatientRegisterViewModel";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { Patient } from "@/interfaces/Patient";
import swal from "sweetalert";
import Password from "primevue/password";
import { ProductRxGroup } from "@/interfaces/ProductRxGroup";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";
import Vue, { PropType } from "vue";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";

export default mixins(CustomValidation, Common).extend({
  props: {
    q: Object as PropType<QuestViewModel> | PropType<PaymentViewModel>,
  },
  mixins: [CustomValidation, Common],
  name: "sign-up",
  data() {
    return {
      password: "" as string,
      confirmPassword: "" as string,
      firstName: "" as string,
      lastName: "" as string,
      email: "" as string,
      eightChars: false,
      oneNumber: false,
      lowerCase: false,
      upperCase: false,
      match: false,
      enableSubmit: false,
      promoCode: "" as string,
      captchaKey: false,
      iAgree: false,
      schema: object().shape({
        password: string().typeError("Password is required.").required("Password is required."),
        confirmPassword: string().typeError("Password is required.").required("Password is required."),
        firstName: string().typeError("First Name is required.").required("First Name is required."),
        lastName: string().typeError("Last Name is required.").required("Last Name is required."),
        email: string().typeError("Email is required").email("The Email field is not a valid e-mail address.").required("Email is required"),
        iAgree: boolean().typeError("You must agree to the terms of use privacy policy and consent to remote health.").oneOf([true], "You must agree to the terms of use privacy policy and consent to remote health.").required("You must agree to the terms of use privacy policy and consent to remote health."),
      }),
      isPasswordShow: false as boolean,
      typedName: "" as string,
      step: 0 as number,
      showSignatureError: false as boolean,
      isConfirmPasswordVisible: false as boolean,
      selectedProductGroups: null as ProductRxGroup[] | null,
      selectedProducts: null as AffiliateProductRx[] | null,
      modalStartTime: null,
      modalEndTime: null,
      timeModalOpen: null,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      QuestType: "getCurrentQuestType",
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
    }),
    StartBtnTxt(): string {
      if (this.QuestType == 4) {
        return "Sign Up";
      } else {
        return this.Content && this.Content.SignUpButtonText ? this.Content.SignUpButtonText : "Begin Visit";
      }
    },
    fullName(): string {
      return `${this.firstName} ${this.lastName}`;
    },
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    productGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    currentQTP(): QuestionnaireTypeProductViewModel {
      const currentQTP = this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID);
      return currentQTP && currentQTP.length && currentQTP[0] ? currentQTP[0] : null;
    },
  },
  watch: {
    password: function () {
      this.validatePassword();
    },
    confirmPassword: function () {
      this.validatePassword();
    },
  },
  created() {
    this.setupValidation(this.schema);
  },
  mounted() {
    this.importData(this.q.quest);
    this.trackTimeOnPage();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async recaptcha(): Promise<string | null> {
      let token = null;
      try {
        await (this as any as IReCaptchaVue).$recaptchaLoaded();
        (this as any as IReCaptchaVue).$recaptchaInstance.showBadge();
        token = await (this as any as IReCaptchaVue).$recaptcha("login");
        (this as any as IReCaptchaVue).$recaptchaInstance.hideBadge();
      } catch (e) {
        console.log(e);
      }
      return token;
    },
    getDiscountBubble(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).DiscountBubble;
      }
      return null;
    },
    getStockBubble(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).StockBubble;
      }
      return null;
    },
    async importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as AffiliateProductRx[];
      }
      console.log("DATA PRODUCT GROUPS:", this.selectedProductGroups, "DATA PRODUCTS:", this.selectedProducts);
    },
    async OnSubmit() {
      this.setLoading(true);
      if (this.$gtm && this.$gtm.enabled() && this.Content.GTMConfig) {
        window.dataLayer?.push({
          event: "signup",
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        });
      }
      const rt = await this.recaptcha();
      if (rt && rt.length > 0) {
        this.errors = [];
        this.schema
          .validate(this, { abortEarly: false })
          .then(async () => {
            this.errors = [];
            let prVM: PatientRegisterViewModel = { questionnaireTypeID: this.QuestType, iAgree: this.iAgree, captchaToken: rt, email: this.email, firstName: this.firstName, lastName: this.lastName, password: this.password, promoCode: this.promoCode };
            let pVM = (await store.dispatch("PatientRegister", prVM)) as AjaxReturnViewModel<Patient>;
            if (!pVM || !pVM.success) {
              this.setLoading(false);
              swal("Error", "Error creating user. If you already have an account a password reset email has been sent. If not, please try again.");
              return;
            }
            var signedIn = await store.dispatch("SignIn", { u: prVM.email, p: prVM.password });
            if (!signedIn) {
              this.setLoading(false);
              swal("Error", "Error signing in. If you already have an account a password reset email has been sent. If not, please try again.");
              return;
            }
            this.$emit("saveandnavigate", "+");
          })
          .catch((err: { inner: any[] }) => {
            this.setLoading(false);
            err.inner.forEach((error: { path: string; message: string }): void => {
              this.errors.push({ key: error.path, value: error.message });
            });
          });
      } else {
        this.setLoading(false);
        swal("Error", "There was an error verifying that you are not a bot. Please refresh the screen and try again.");
      }
    },
    validatePassword: function () {
      this.enableSubmit = false;

      if (this.password && this.password.length > 7) {
        this.eightChars = true;
      } else {
        this.eightChars = false;
      }
      var rgxON = RegExp("[0-9]");
      if (this.password && rgxON.test(this.password)) {
        this.oneNumber = true;
      } else {
        this.oneNumber = false;
      }
      var lcRGX = RegExp("[a-z]");
      if (this.password && lcRGX.test(this.password)) {
        this.lowerCase = true;
      } else {
        this.lowerCase = false;
      }
      var ucRGX = RegExp("[A-Z]");
      if (this.password && ucRGX.test(this.password)) {
        this.upperCase = true;
      } else {
        this.upperCase = false;
      }
      if (this.isConfirmPasswordVisible) {
        if (this.password && this.confirmPassword && this.password == this.confirmPassword) {
          this.match = true;
        } else {
          this.match = false;
        }
      } else if (!this.isConfirmPasswordVisible && this.password) {
        this.match = true;
        this.confirmPassword = this.password;
      } else {
        this.match = false;
      }
      if (this.eightChars && this.oneNumber && this.lowerCase && this.upperCase && this.match) {
        this.enableSubmit = true;
      }
      console.log("match:", this.match);
    },
    /*getCode: function () {
      if (ed.common.qs["code"] && ed.common.qs["code"].length > 0) {
        this.GetPromoByCode(ed.common.qs["code"], function () {
          $(".form").attr("action", $(".form").attr("action") + "?code=" + ed.common.qs["code"]);
          swal("Oh Yeah!", "Your coupon code (" + this.promoCode + ") will be applied when checking out!", "info");
        });
      }
    },*/
    async GetPromoByCode(code: string | null, cb: () => void, failCB: () => void) {
      if (!code) return;
      const codePromo = await store.dispatch("GetPromoByCode", { t: this.QuestType, code: code, notInitialQuest: false });
      if (codePromo && codePromo.data) {
        this.promoCode = code;
        if (cb) cb();
      } else {
        if (failCB) failCB();
      }
    },
    togglePassword() {
      this.isPasswordShow = !this.isPasswordShow;
    },

    async confirmTerms() {
      if (this.$attrs["confirm-terms"]) {
        this.$bvModal.show("confirm-terms-modal");
        this.step = 0;
      }
    },
    confirmAndClose() {
      if (this.typedName === this.fullName) {
        this.iAgree = true;
        this.step = 1;
        setTimeout(() => {
          this.$bvModal.hide("confirm-terms-modal");
        }, 2000);
      } else {
        this.showSignatureError = true;
      }
    },
    cancelAndClose() {
      this.iAgree = false;
      this.$bvModal.hide("confirm-terms-modal");
    },
    getProductRxImage(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProductRxImage;
      }
      return null;
    },
    getDrugNameAlt(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).RxTitleOverride;
      }
      return null;
    },
    getBoxIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).BoxIncludes;
      }
      return null;
    },
    getProgramIncludesTitle(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludesTitle;
      }
      return null;
    },
    getProgramAlsoIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludesV2;
      }
      return null;
    },
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    getPricePerMonth(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).PricePer;
      }
      return null;
    },
    getComparedPrice(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ComparedPrice;
      }
      return null;
    },
    getTotalSavings(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).TotalSavings;
      }
      return null;
    },
    openWelcomeModal() {
      this.$bvModal.show("welcome-message");
      this.modalStartTime = performance.now();
      setTimeout(() => {
        this.modalClickListener();
      }, 1000);
    },
    trackTimeOnPage() {
      if (this.Content && this.Content.WelcomeModalContent) {
        setTimeout(() => {
          this.openWelcomeModal();
        }, this.Content.WelcomeModalTimer);
      }
    },
    modalClickListener() {
      const button = document.getElementById("close-modal");
      if (button) {
        button.addEventListener("click", () => {
          this.$bvModal.hide("welcome-message");
          this.modalEndTime = performance.now();
          this.timeModalOpen = Math.round(((this.modalEndTime - this.modalStartTime) / 1000) * 10) / 10;
          console.log(this.modalEndTime, this.modalStartTime);
          console.log("Open Modal", this.timeModalOpen);
        });
      }
    },
  },
  components: { QuestWrapperComponent, BModal, Password },
});
