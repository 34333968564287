import Vue, { PropType } from "vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PromotionProduct } from "../interfaces/PromotionProduct";
import { PromoViewModel } from "../interfaces/PromoViewModel";
import { mapGetters } from "vuex";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import { ProductRxGroup } from "@/interfaces/ProductRxGroup";
import store from "@/store";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";
import { VisitType } from "@/interfaces/VisitType";

export default Vue.extend({
  props: {
    adjustedDoctorConsult: { type: Number, default: 0 },
    programFee: { type: Number, default: 0 },
    adjustedProgram: { type: Number, default: 0 },
    isPayment: { type: Boolean, default: false },
    isRefill: { type: Boolean, default: false },
    isModify: { type: Boolean, default: false },
    isSubChargePayment: { type: Boolean, default: false },
    q: Object as PropType<QuestViewModel> | PropType<PaymentViewModel>,
    totalPrice: { type: Number, default: 0 },
  },
  data() {
    return {
      codePromo: null as PromoViewModel | null,
      isExpeditedShipping: false as boolean | null,
      isSubscription: false,
      promoCode: null as string | null,
      selectedProductGroups: null as ProductRxGroup[] | null,
      selectedProducts: null as AffiliateProductRx[] | null,
      selectedProductTypeID: 1,
      visitTypeID: VisitType.QuestionnaireOnly as VisitType,
    };
  },
  computed: {
    ...mapGetters({ QuestionnaireTypeProducts: "getQuestionnaireTypeProducts", PassedPromoCode: "getPassedPromoCode" }),
    consultFee(): number {
      switch (this.visitTypeID) {
        case VisitType.Instant:
          return this.q && this.q.instantConsult ? this.q.instantConsult.feeAmount : 0;
          break;
        case VisitType.QuestionnaireOnly:
          return this.q && this.q.questOnlyConsult ? this.q.questOnlyConsult.feeAmount : 0;
          break;
        case VisitType.Scheduled:
          return this.q && this.q.scheduledConsult ? this.q.scheduledConsult.feeAmount : 0;
          break;
      }
      return 0;
    },
    oldConsultFee(): number {
      switch (this.visitTypeID) {
        case VisitType.Instant:
          return this.q && this.q.instantConsult ? this.q.instantConsult.oldFeeAmount : 0;
          break;
        case VisitType.QuestionnaireOnly:
          return this.q && this.q.questOnlyConsult ? this.q.questOnlyConsult.oldFeeAmount : 0;
          break;
        case VisitType.Scheduled:
          return this.q && this.q.scheduledConsult ? this.q.scheduledConsult.oldFeeAmount : 0;
          break;
      }
      return 0;
    },
    currentQTP(): QuestionnaireTypeProductViewModel {
      const currentQTP = this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID);
      return currentQTP && currentQTP.length && currentQTP[0] ? currentQTP[0] : null;
    },
    shoppingCartItemTotal(): number {
      if (this.q.quest.shoppingCartItems && this.q.quest.shoppingCartItems.length > 0) return this.q.quest.shoppingCartItems.reduce((partialSum, a) => partialSum + a.subTotal, 0);
      return 0;
    },
    adjustedDoctorConsultfee(): number {
      const adcf = this.mixinAdjustedDoctorConsultfee(this.isPayment, this.isRefill, this.q, this.everyonePromoProd, this.codePromoProd);
      return adcf ? adcf : 0;
    },
    adjustedProgramFee(): number {
      return this.mixinAdjustedProgramFee(this.isPayment, this.isRefill, this.q, this.everyonePromoProd, this.codePromoProd);
    },
    codePromoProd(): PromotionProduct | null {
      return this.mixinProductReviewGetPromoProd(this.codePromo);
    },
    productRxProgramPrice(): number {
      let totalDP = 0;
      this.selectedProducts?.forEach((sp) => {
        if (!sp || sp.id === 0) {
          totalDP += 0;
        }
        if (this.isSubscription) {
          if (sp.subscriptionProgramPrice && sp.subscriptionProgramPrice > 0) {
            const qpr = this.q.quest.qProductRxs?.filter((x) => x.productRxID == sp.id)[0];
            totalDP += sp.subscriptionProgramPrice / (qpr && qpr.numberOfSubscriptionChargesRequired && qpr.numberOfSubscriptionChargesRequired > 0 ? qpr.numberOfSubscriptionChargesRequired : 1);
          }
        } else {
          if (this.selectedProductTypeID === 1) totalDP += sp.programPrice ? sp.programPrice : 0;
        }
      });
      console.log("productRxProgramPrice");
      console.log(this.selectedProducts);
      console.log(totalDP);
      return totalDP;
    },
    everyonePromoProd(): PromotionProduct | null {
      return this.mixinProductReviewGetPromoProd(this.q.everyonePromo);
    },
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    productGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    programFeeComputed(): number {
      const p = this.isSubscription ? this.productRxProgramPrice : (this.currentQTP && this.currentQTP.price ? this.currentQTP.price : 0) + this.productRxProgramPrice;
      this.$emit("update:programFee", p);
      return p;
    },
    shippingPrice(): number {
      if (this.isExpeditedShipping) return this.q.expeditedShippingFee;
      else if (this.q.standardShippingFee) return this.q.standardShippingFee;
      else return 0;
    },
    totalPriceComputed(): number {
      const result = this.shippingPrice + this.adjustedProgramFee + this.adjustedDoctorConsultfee + this.shoppingCartItemTotal;
      this.$emit("update:adjustedDoctorConsult", this.adjustedDoctorConsultfee);
      this.$emit("update:adjustedProgram", this.adjustedProgramFee);
      this.$emit("update:totalPrice", result);
      return result;
    },
    codePromoDiscountAmount(): number {
      return this.mixinGetPromoDiscountAmount(this.codePromoProd);
    },
    subscriptionProgramPrice(): number {
      let result = 0;
      if (!this.isSubscription) return result;
      this.selectedProducts?.forEach((sp) => {
        if (!sp || sp.id === 0) {
          result += 0;
        }
        if (sp.subscriptionProgramPrice && sp.subscriptionProgramPrice > 0) {
          result += sp.subscriptionProgramPrice;
        }
      });
      return result;
    },
  },
  methods: {
    mixinAdjustedProgramFee(isPayment: boolean, isRefill: boolean, q: QuestViewModel | PaymentViewModel, everyonePromoProd: PromotionProduct | null, codePromoProd: PromotionProduct | null): number {
      const fee = this.programFeeComputed;
      let af = fee;
      if (this.codePromoProd) af = fee + this.mixinGetProgramPromoDiscountAmount(this.codePromoProd);
      if (af == fee) {
        if (everyonePromoProd) af = fee + this.mixinGetProgramPromoDiscountAmount(everyonePromoProd);
      }
      this.$emit("update:adjustedProgram", af);
      return af;
    },
    mixinAdjustedDoctorConsultfee(isPayment: boolean, isRefill: boolean, q: QuestViewModel | PaymentViewModel, everyonePromoProd: PromotionProduct | null, codePromoProd: PromotionProduct | null): number {
      let af = !isPayment && !isRefill && !this.isModify ? this.consultFee : 0;
      if (codePromoProd && codePromoProd.drVisitFlatFeeDiscount && codePromoProd.drVisitFlatFeeDiscount > 0) {
        if (codePromoProd.promotionAppliedToType == 0 || codePromoProd.promotionAppliedToType == 2) {
          af = af - codePromoProd.drVisitFlatFeeDiscount;
        }
      } else {
        if (codePromoProd && (codePromoProd.promotionAppliedToType == 0 || codePromoProd.promotionAppliedToType == 2)) {
          af = af - (codePromoProd.drVisitPercentageDiscount ? codePromoProd.drVisitPercentageDiscount : 0) * this.consultFee;
        }
      }
      if (af == (!isPayment && !isRefill && !this.isModify ? this.consultFee : 0)) {
        if (everyonePromoProd && everyonePromoProd.drVisitFlatFeeDiscount && everyonePromoProd.drVisitFlatFeeDiscount > 0) {
          if (everyonePromoProd.promotionAppliedToType == 0 || everyonePromoProd.promotionAppliedToType == 2) {
            af = af - everyonePromoProd.drVisitFlatFeeDiscount;
          }
        } else {
          if (everyonePromoProd && (everyonePromoProd.promotionAppliedToType == 0 || everyonePromoProd.promotionAppliedToType == 2)) {
            af = af - (everyonePromoProd.drVisitPercentageDiscount ? everyonePromoProd.drVisitPercentageDiscount : 0) * this.consultFee;
          }
        }
      }
      if (af < 0) {
        af = 0;
      }
      this.$emit("update:adjustedDoctorConsult", af);
      return af;
    },
    mixinGetProgramPromoDiscountAmount(promoProd: PromotionProduct) {
      if (promoProd == null) {
        return 0;
      }
      if (promoProd.promotionAppliedToType == 3) {
        const qpr = this.q.quest.qProductRxs?.filter((x) => x.productRxID == promoProd.productRxID)[0];
        const numberOfSubscriptionChargesRequired = qpr && qpr.numberOfSubscriptionChargesRequired && qpr.numberOfSubscriptionChargesRequired > 0 ? qpr.numberOfSubscriptionChargesRequired : 1;
        if (promoProd.flatFeeDiscount && promoProd.flatFeeDiscount > 0) {
          return -(promoProd.flatFeeDiscount / numberOfSubscriptionChargesRequired);
        } else {
          return -(promoProd.percentageDiscount ? promoProd.percentageDiscount : 0) * this.programFeeComputed;
        }
      }
      return 0;
    },
    mixinGetDrVisitPromoDiscountAmount(promoProd: PromotionProduct) {
      if (promoProd == null) {
        return 0;
      }
      if (promoProd.promotionAppliedToType == 0 || promoProd.promotionAppliedToType == 2) {
        if (promoProd.drVisitFlatFeeDiscount && promoProd.drVisitFlatFeeDiscount > 0) {
          return -promoProd.drVisitFlatFeeDiscount;
        } else {
          return -(promoProd.drVisitPercentageDiscount ? promoProd.drVisitPercentageDiscount : 0) * this.consultFee;
        }
      }
      return 0;
    },
    mixinGetPromoDiscountAmount(promoProd: PromotionProduct | null) {
      if (promoProd == null) {
        return 0;
      }
      let discountAmount = 0;
      discountAmount = this.mixinGetProgramPromoDiscountAmount(promoProd);
      if (!discountAmount && !this.isPayment && !this.isRefill && !this.isModify) discountAmount = this.mixinGetDrVisitPromoDiscountAmount(promoProd);
      return discountAmount;
    },
    mixinGetPromoProd(promo: PromoViewModel | null, selectedProductID: number | null, isSubscription: boolean): PromotionProduct | null {
      if (!selectedProductID || selectedProductID === 0 || promo == null || promo.products == null) {
        return null;
      }
      /*if (isSubscription && !promo.isPerpetualLink) {
        return null;
      }*/
      for (let i = 0; i < promo.products.length; i++) {
        if (promo.products[i].productRxID === selectedProductID) {
          return promo.products[i];
        }
      }
      return null;
    },
    mixinProductReviewGetPromoProd(promo: PromoViewModel | null): PromotionProduct | null {
      if (!this.selectedProducts || (this.selectedProducts.length > 0 && this.selectedProducts[0].id === 0) || promo == null || promo.products == null) {
        return null;
      }

      const localSelectedProd = this.selectedProducts?.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
      if (!localSelectedProd || localSelectedProd.length == 0 || (localSelectedProd.length > 0 && localSelectedProd[0].id === 0)) {
        return null;
      }
      /*if (this.isSubscription && !promo.isPerpetualLink) {
        this.promoCode = null;
        this.codePromo = null;
        return null;
    }*/
      return this.mixinGetPromoProd(promo, localSelectedProd[0].id, this.isSubscription);
    },
    async GetPromoByCode(code: string | null, cb?: null | (() => void), failCB?: null | (() => void)) {
      if (!code) return;
      this.codePromo = await store.dispatch("GetPromoByCode", { t: this.q.quest.questionnaireTypeID, code: code, notInitialQuest: false });
      if (this.codePromo) {
        this.promoCode = code;
        if (cb) cb();
      } else {
        this.codePromo = null;
        if (failCB) failCB();
      }
    },
  },
});
