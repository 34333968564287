
import { BCol, BContainer, BRow } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ScreenEnum } from "@/interfaces/ScreenEnum";
import store from "@/store/index";
import router from "@/router";
import moment from "moment";
import { SubscriptionFrequency } from "@/interfaces/SubscriptionFrequency";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { PhotosUpdateViewModel } from "@/interfaces/PhotosUpdateViewModel";
import { PhotosViewModel } from "@/interfaces/PhotosViewModel";

export default Vue.extend({
  props: {
    outsidePromo: { type: String, default: null },
  },
  data() {
    return {
      placeholder: false,
      screen: 0 as number,
      selectedProductSubscriptionAvailable: false as boolean,
      selectedFrequency: null as SubscriptionFrequency | null,
      adjustedDoctorConsult: 0 as number,
      adjustedProductPrice: 0 as number,
      medTotal: 0 as number,
      totalPrice: 0 as number,
      photosQuestType: 0 as number,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Photos: "getPhotos",
      SignedIn: "getSignedIn",
      CurrentQuestType: "getCurrentQuestType",
      IsQuickLink: "getIsVerifiedQuickLink",
    }),
    q(): PhotosUpdateViewModel {
      return this.Photos[this.photosQuestType];
    },
  },
  async created() {
    this.photosQuestType = this.CurrentQuestType;
    if (!this.photosQuestType) {
      router.push("/"); // Got here without picking a condition
      return;
    }
    if (!this.SignedIn) {
      if (router.currentRoute.name != "/signin") router.push("/signin");
      return;
    }

    if (this.IsQuickLink) {
      this.setIsVerifiedQuickLink(false);
      console.log(`quick link cleared`);
    }
    this.init();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setIsVerifiedQuickLink: "setIsVerifiedQuickLink",
    }),
    async init() {
      this.setLoading(true);
      console.log(router.currentRoute.name);
      switch (router.currentRoute.name) {
        case "/photos/identityupload":
          this.setScreen(ScreenEnum.IdentityUpload, true);
          break;
        case "/photos/error":
          this.setScreen(ScreenEnum.Error, true);
          break;
        case "/photos/photosupdatecomplete":
          this.setScreen(ScreenEnum.PhotosUpdateComplete, true);
          break;
        default:
          break;
      }
      var arVM: AjaxReturnViewModel<PhotosViewModel> = await store.dispatch("GetPhotos", { t: this.photosQuestType });
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        this.setLoading(false);
        return;
      }
      var q: PhotosViewModel = arVM.data;
      if (!q) {
        router.push("/photos/error");
        this.setLoading(false);
        return;
      }
      this.pageLoadNavigate();
      this.setLoading(false);
    },
    async navigate(dir: string, lastQuestionScreen?: number | null, forceScreenChange?: boolean | null, skipShipping?: boolean | null) {
      switch (this.screen) {
        case ScreenEnum.IdentityUpload:
          if (dir === "+") this.setScreen(ScreenEnum.PhotosUpdateComplete);
          else router.push("/patientportal");
          break;
        case ScreenEnum.PhotosUpdateComplete:
          if (dir === "+") router.push("/patientportal");
          else this.setScreen(ScreenEnum.IdentityUpload);
          break;
        default:
        // do nothing
      }
      this.setLoading(false);
    },
    pageLoadNavigate() {
      this.setScreen(ScreenEnum.IdentityUpload);
    },
    async saveandnavigate(dir: string, data: any) {
      var skipShipping = false;
      window.scrollTo(0, 0);
      this.setLoading(true);
      console.log(`saveandnavigate ${this.screen} ${dir}`);
      switch (this.screen) {
        case ScreenEnum.IdentityUpload:
          await store.dispatch("SavePhotos", { p: data, t: this.photosQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.PhotosUpdateComplete:
          this.navigate(dir);
          break;
        default:
        // do nothing
      }
    },
    setScreen(s: number | ScreenEnum, noPush?: boolean) {
      console.log(`setScreen ${s}`);
      if (s !== ScreenEnum.Questions && s === this.screen) return;
      this.screen = s;

      if (!noPush) {
        switch (s) {
          case ScreenEnum.IdentityUpload:
            router.push("/photos/identityupload");
            break;
          case ScreenEnum.PhotosUpdateComplete:
            router.push("/photos/photosupdatecomplete");
            break;
          case ScreenEnum.Error:
            router.push("/photos/error");
            break;
          default:
            break;
        }
      }
      this.setLoading(false);
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
  },
});
