
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { string, object, bool } from "yup";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { mapGetters } from "vuex";
import { PropType } from "vue";
import PicInputVueComponentVue from "@/components/PicInputVueComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import { PhotosViewModel } from "@/interfaces/PhotosViewModel";
import { BModal } from "bootstrap-vue";
import LottieAnimation from "@/components/LottieAnimationsComponent.vue";

enum screenview {
  selfie = 0,
  id = 1,
}

export default mixins(CustomValidation).extend({
  mixins: [CustomValidation],
  name: "identity-upload",
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    isPhotoUpdate: { type: Boolean, default: false },
  },
  data() {
    return {
      view: screenview.selfie,
      schema: object().shape({
        selfieUrlInUse: bool(),
        selfieBase64: string()
          .nullable()
          .when("selfieUrlInUse", {
            is: (selfieUrlInUse: boolean) => !selfieUrlInUse,
            then: string().nullable().required("Selfie is required.").typeError("Selfie is required."),
          }),
        officialIDUrlInUse: bool(),
        officialIDBase64: string()
          .nullable()
          .when("officialIDUrlInUse", {
            is: (officialIDUrlInUse: boolean) => !officialIDUrlInUse,
            then: string().nullable().required("Official ID is required.").typeError("Official ID is required."),
          }),
      }),
      isEventListenerAdded: false as boolean,
      isEventListenerAddedID: false as boolean,
      selfieBase64: null as string | null,
      officialIDBase64: null as string | null,
      selfieUrl: "" as string,
      officialIDUrl: "" as string,
      officialIDUrlInUse: false,
      selfieUrlInUse: false,
      showUpdateRequiredError: false,
      showCantMatchError: false,
      selfieText: `
        <div class="text-center mb-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
        </svg>
        </div>
        <div class="small">Choose a selfie from your files</div>
        <div class="small text-muted">JPG and PNG formats, up to 5mb</div>
        <div class="small font-weight-bold mt-2">Upload Photo</div>
      `,
      idText: `
        <div class="text-center mb-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
        </svg>
        </div>
        <div class="small">Choose photo from your files</div>
        <div class="small text-muted">JPG and PNG formats, up to 5mb</div>
        <div class="small font-weight-bold mt-2">Upload Photo</div>
      `,
    };
  },
  created() {
    this.importData(this.Patient);
    this.setupValidation(this.schema);
  },
  watch: {
    Patient(newVal) {
      this.importData(newVal);
    },
    base64Photos(newVal) {
      this.selfieBase64 = newVal.SelfieBase64;
      this.officialIDBase64 = newVal.OfficialIDBase64;
    },
    /*selfieBase64(newVal, oldVal) {
      if (newVal != oldVal) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".official-id-scroll").offset().top - 25,
          },
          2000
        );
      }
    },*/
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    Base64Photos(): PhotosViewModel {
      return this.q.base64Photos;
    },
    Patient(): PatientViewModel {
      return this.q.patient;
    },
    title(): string {
      if (!this.isPhotoUpdate) return "Smile! We need to verify your ID.";
      else return "Update Your Smile!";
    },
    stepType(): string {
      return this.isPhotoUpdate ? "none" : "new";
    },
    step(): string {
      return this.q.photoEntryAfterCheckout ? "con" : "que";
    },
  },
  mounted() {
    this.controlPhotoModal();
  },
  methods: {
    updateSelfie(event: any) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (!this.isEventListenerAdded) {
        reader.addEventListener(
          "load",
          () => {
            // Convert the image file to a base64 string
            this.selfieBase64 = reader.result;
          },
          false
        );
        this.isEventListenerAdded = true;
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    updateID(event: any) {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (!this.isEventListenerAddedID) {
        reader.addEventListener(
          "load",
          () => {
            // Convert the image file to a base64 string
            this.officialIDBase64 = reader.result;
          },
          false
        );
        this.isEventListenerAddedID = true;
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      const fileInput = this.$refs.selfieInput as HTMLInputElement;
      if (fileInput) {
        fileInput.click();
      }
    },
    triggerIDFileInput() {
      const fileInput = this.$refs.idInput as HTMLInputElement;
      if (fileInput) {
        fileInput.click();
      }
    },
    modalClickListener() {
      const button = document.getElementById("close-modal");
      if (button) {
        button.addEventListener("click", () => {
          this.$bvModal.hide("photo-modal");
        });
      }
    },
    btnOfficialIDChangePhoto_click() {
      this.officialIDUrlInUse = false;
      this.showUpdateRequiredError = false;
      this.showCantMatchError = false;
    },
    btnSelfieChangePhoto_click() {
      this.selfieUrlInUse = false;
      this.showUpdateRequiredError = false;
      this.showCantMatchError = false;
    },
    importData(newVal: PatientViewModel | null) {
      if (!newVal) return;

      this.officialIDUrl = newVal.photo1Path ? newVal.photo1Path : "";
      if (this.officialIDUrl && this.officialIDUrl.length > 0) {
        this.officialIDUrlInUse = true;
      }
      this.selfieUrl = newVal.photo2Path ? newVal.photo2Path : "";
      if (this.selfieUrl && this.selfieUrl.length > 0) {
        this.selfieUrlInUse = true;
      }
    },
    getUpdatedData() {
      var p = Object.assign({}, this.Base64Photos);
      p.selfieBase64 = this.selfieBase64 ? this.selfieBase64 : "";
      p.officialIDBase64 = this.officialIDBase64 ? this.officialIDBase64 : "";
      return p;
    },
    next_click() {
      this.errors = [];
      if ((!this.selfieBase64 && this.$refs.selfieInfoDiv) || (!this.selfieUrlInUse && this.$refs.selfieInfoDiv)) {
        this.$refs.selfieInfoDiv.scrollIntoView({ behavior: "smooth" });
      }
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          var data = this.getUpdatedData();
          if ((!this.officialIDUrlInUse && !this.officialIDBase64) || (!this.selfieUrlInUse && !this.selfieBase64)) {
            return;
          }

          if (this.isPhotoUpdate && this.officialIDUrlInUse && this.selfieUrlInUse) {
            this.showUpdateRequiredError = true;
            return;
          }

          if (!this.officialIDUrlInUse && !this.selfieUrlInUse && this.officialIDBase64 && this.officialIDBase64 === this.selfieBase64) {
            this.showCantMatchError = true;
            return;
          }

          if (this.officialIDUrlInUse && this.selfieUrlInUse) {
            data.dontSave = true;
          } else {
            delete data.dontSave;
          }

          this.$emit("saveandnavigate", "+", data);
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    prev_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          var data = this.getUpdatedData();

          if ((!this.officialIDUrlInUse && !this.officialIDBase64) || (!this.selfieUrlInUse && !this.selfieBase64)) {
            return;
          }

          if (this.isPhotoUpdate && this.officialIDUrlInUse && this.selfieUrlInUse) {
            this.showUpdateRequiredError = true;
            return;
          }

          if (this.officialIDUrlInUse && this.selfieUrlInUse) {
            data.dontSave = true;
          } else {
            delete data.dontSave;
          }

          this.$emit("saveandnavigate", "-", data);
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    openPhotoModal() {
      this.$bvModal.show("photo-modal");
      setTimeout(() => {
        this.modalClickListener();
      }, 1000);
    },
    controlPhotoModal() {
      if (this.Content && this.Content.PhotoModalContent && !this.isPhotoUpdate) {
        setTimeout(() => {
          this.openPhotoModal();
        }, this.Content.PhotoModalTimer);
      }
    },
  },
  components: { QuestWrapperComponent, PicInputVueComponentVue, BModal, LottieAnimation },
});
