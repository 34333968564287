
import Vue from "vue";
import store from "@/store";
import { DestinationTypeIDEnum } from "@/interfaces/DestinationTypeIDEnum";

import { mapMutations } from "vuex";

export default Vue.extend({
  async beforeMount() {
    if (this.$route.query.id && this.$route.query.t) {
      await store
        .dispatch("quickLinkAuthentication", this.$route.query)
        .then((result) => {
          if (result == DestinationTypeIDEnum.PatientPortal) {
            this.setQuickLinkRoute("/patientportal");
            this.$router.push("/patientportal");
          }

          if (result === DestinationTypeIDEnum.UploadPhotos) {
            this.setQuickLinkRoute("/photos");
            this.$router.push("/photos");
          }

          if (result === DestinationTypeIDEnum.Reschedule) {
            this.setQuickLinkRoute("/patientportal");
            this.$router.push("/patientportal");
          }

          if (result === DestinationTypeIDEnum.Schedule) {
            this.setQuickLinkRoute("/questionnaire");
            this.$router.push("/questionnaire");
          }

          if (result === DestinationTypeIDEnum.Payment) {
            this.setQuickLinkRoute("/payment");
            this.$router.push("/payment");
          }
        })
        .catch((e) => console.log(`error: ${e}`));
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setQuickLinkRoute: "setQuickLinkRoute",
    }),
  },
});
