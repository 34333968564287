
import Vue, { PropType } from "vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { ProductRxGroup } from "@/interfaces/ProductRxGroup";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { mapGetters } from "vuex";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import store from "@/store";
import { ProductRxSubscriptionFrequencyViewModel } from "@/interfaces/ProductRxSubscriptionFrequencyViewModel";
import { ProductRx } from "@/interfaces/ProductRx";
import { boolean, number, string } from "yup";
import { filter } from "vue/types/umd";
import router from "@/router";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";

export default Vue.extend({
  name: "get-started",
  props: {
    q: Object as PropType<QuestViewModel>,
    queryproductrxid: { type: number, default: null },
    queryproductrxsubscriptionfrequencyid: { type: number, default: null },
    fromCheckout: { type: String, default: null },
  },
  data() {
    return {
      subscriptionPlanID: 0,
      computing: true,
      isSubscription: true,
      freqs: [] as ProductRxSubscriptionFrequencyViewModel[],
      selectedFrequency: null as ProductRxSubscriptionFrequencyViewModel | null,
      selectedProductGroup: null as ProductRxGroup | null,
      selectedProduct: null as AffiliateProductRx | null,
      selectedProductTypeID: 1,
      isSubscriptionDiscountAvailable: false,
      fillSubscriptionFrequencyDays: null as number | null,
      chargeSubscriptionFrequencyDays: null as number | null,
      swiper: null as any | null,
      selectedRxType: "" as string,
      showSwiperArrowLeft: true as boolean,
      showSwiperArrowRight: true as boolean,
      isShipmentOpen: [],
      isProgramIncludesOpen: [],
    };
  },
  async mounted() {
    var i = 0;
    this.q.products
      .filter((x: AffiliateProductRx) => x.productRxGroupID > 0 && x.isGetStartedProductRx)
      .forEach(async (x: AffiliateProductRx) => {
        i += 1;
        var freqs = await this.getFreqsByProductID(x.id);
        freqs.forEach((x: ProductRxSubscriptionFrequencyViewModel) => {
          if (this.freqs.findIndex((y) => y.chargeSubscriptionFrequencyID == x.chargeSubscriptionFrequencyID && y.fillSubscriptionFrequencyID == x.fillSubscriptionFrequencyID) == -1) {
            this.freqs.push(x);
          }
        });
        // have to put this here, can't rely on this.freqs having the data if we run importData outside of this async lambda
        if (i == this.q.products.filter((x: AffiliateProductRx) => x.productRxGroupID > 0 && x.isGetStartedProductRx).length) this.importData(this.q.quest);
      });

    Vue.nextTick(() => {
      this.swiper?.destroy();
      this.startSwiper();
    });
  },
  watch: {
    q(newVal) {
      this.importData(newVal);
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
      SignedIn: "getSignedIn",
    }),
    currentQTP(): QuestionnaireTypeProductViewModel | null {
      if (!this.q.quest || !this.q.quest) return null;
      const currentQTP = this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID);
      return currentQTP && currentQTP.length && currentQTP[0] ? currentQTP[0] : null;
    },
    stepType(): string {
      return "newsub"; //this.isPayment ? "payment" : "refill";
    },
    getAnimationStyle() {
      return {
        animation: "squeeze 3s ease-in-out infinite",
      };
    },
    products(): Array<AffiliateProductRx> {
      return this.q.products;
    },
    productGroups(): Array<ProductRxGroup> {
      return this.q.productGroups;
    },
    filteredProduct(): Array<AffiliateProductRx> {
      return this.q.products
        .filter((x: AffiliateProductRx) => this.getRxType(x.contentJSON) == this.selectedRxType || this.selectedRxType == "")
        .filter((x) => x.productRxGroupID > 0 && x.isGetStartedProductRx)
        .sort((a, b) => {
          if (this.getOrder(a.contentJSON) < this.getOrder(b.contentJSON)) return -1;
          else return 1;
        });
    },
  },
  methods: {
    toggleShipment(index: any) {
      this.$set(this.isShipmentOpen, index, !this.isShipmentOpen[index]);
    },
    toggleProgramIncludes(index: any) {
      this.$set(this.isProgramIncludesOpen, index, !this.isProgramIncludesOpen[index]);
    },
    importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      var qProductRx = null;
      if (newVal.qProductRxs && newVal.qProductRxs.length > 0) {
        var qprs = newVal.qProductRxs.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
        if (qprs && qprs.length > 0) {
          qProductRx = qprs[0];
        }
      }
      this.selectedProductGroup = null;
      this.selectedProduct = null;
      this.isSubscriptionDiscountAvailable = false;
      for (var i = 0; i < this.productGroups.length; i++) {
        if (qProductRx && this.productGroups[i].id === qProductRx.productRxGroupID) {
          this.selectedProductGroup = this.productGroups[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (qProductRx && this.products[i].id === qProductRx.productRxID) {
          this.selectedProduct = this.products[i];
          break;
        }
      }
      for (i = 0; i < this.products.length; i++) {
        if (this.selectedProductGroup && this.products[i].productRxGroupID === this.selectedProductGroup.id) {
          if (this.selectedProduct == null) {
            this.selectedProduct = this.products[i];
          }
          var p = this.products[i] as AffiliateProductRx;
          if (p && p.subscriptionDiscountPricePerPill && p.subscriptionDiscountPricePerPill > 0) {
            this.isSubscriptionDiscountAvailable = true;
          }
        }
      }
      this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isSubscription = qProductRx ? qProductRx.isSubscription : false;
      this.fillSubscriptionFrequencyDays = qProductRx ? qProductRx.fillSubscriptionFrequencyDays : this.fillSubscriptionFrequencyDays;
      this.chargeSubscriptionFrequencyDays = qProductRx ? qProductRx.chargeSubscriptionFrequencyDays : this.chargeSubscriptionFrequencyDays;
      var fs = this.freqs.filter((x) => x.fillSubscriptionFrequencyDays == this.fillSubscriptionFrequencyDays && x.chargeSubscriptionFrequencyDays == this.chargeSubscriptionFrequencyDays);
      if (fs && fs.length > 0) this.toggleSubscription(true, fs[0]);
      else if (this.freqs && this.freqs.length > 0) this.toggleSubscription(true, this.freqs[0]);
    },
    async getFreqsByProductID(id: number): Promise<ProductRxSubscriptionFrequencyViewModel[]> {
      var freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (freqs) {
        if (freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
      }
      return freqs;
    },
    toggleSubscription(isOn: boolean, f: ProductRxSubscriptionFrequencyViewModel | null) {
      this.isSubscription = isOn;
      this.selectedFrequency = f;
    },
    getFreqDesc(desc: string) {
      var d = desc;
      console.log(d);
      if (this.Content.FreqDesc && this.Content.FreqDesc[desc]) d = this.Content.FreqDesc[desc];
      console.log(d);
      return d;
    },
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    getBoxIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).BoxIncludes;
      }
      return null;
    },
    getRxType(contentJSON: string) {
      if (contentJSON) {
        var rxType = JSON.parse(contentJSON).RxType;
        return rxType ? rxType : "";
      }
      return "";
    },
    getProgramAlsoIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludesV2;
      }
      return null;
    },
    getProgramIncludesTitle(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludesTitle;
      }
      return null;
    },
    getPricePer(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).PricePer;
      }
      return null;
    },
    getProductRxImage(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProductRxImage;
      }
      return null;
    },
    getDiscountBubble(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).DiscountBubble;
      }
      return null;
    },
    getStockBubble(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).StockBubble;
      }
      return null;
    },
    getDrugNameAlt(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).RxTitleOverride;
      }
      return null;
    },
    getOrder(contentJSON: string): number {
      if (contentJSON) {
        return JSON.parse(contentJSON).Order ? JSON.parse(contentJSON).Order : 0;
      }
      return 0;
    },
    isRecommended(p: AffiliateProductRx) {
      if (p.id == 0) return true; //this.q.recommendedSubscriptionPlanID
      else return false;
    },
    planClass(p: AffiliateProductRx) {
      if (this.isRecommended(p)) {
        return "col-10 col-md-5 col-lg-4 order-1 order-md-1 zest-pricing-rec border rounded p-3 p-md-4 mb-4 d-flex flex-column";
      } else {
        return "col-10 col-md-5 col-lg-4 order-2 order-md-2 zest-pricing border rounded p-3 p-md-4 mb-4 d-flex flex-column";
      }
    },
    getProductRxGroup(p: AffiliateProductRx): ProductRxGroup {
      return this.q.productGroups.filter((x) => x.id == p.productRxGroupID)[0];
    },
    getUpdatedData(p: AffiliateProductRx) {
      var q = Object.assign({}, this.q.quest);
      //var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
      var qProductRx = {} as QuestionnaireProductRx;
      if (q.qProductRxs && q.qProductRxs.length > 0) {
        var qprs = q.qProductRxs.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
        if (qprs && qprs.length > 0) {
          qProductRx = qprs[0];
        }
      }
      // Save other qprs
      var otherQPRs = q.qProductRxs ? q.qProductRxs.filter((x) => !x.productRxGroupID) : [];

      // Setup main qpr
      qProductRx.productRxID = p.id;
      qProductRx.fillSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.fillSubscriptionFrequencyDays : null;
      qProductRx.chargeSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.chargeSubscriptionFrequencyDays : null;
      qProductRx.numberOfSubscriptionChargesRequired = this.selectedFrequency ? this.selectedFrequency.numberOfSubscriptionChargesRequired : null;
      qProductRx.isSubscription = this.isSubscription;
      qProductRx.productRxGroupID = p.productRxGroupID;
      qProductRx.active = true;
      q.qProductRxs = [qProductRx];

      // Add other qprs back with same subscription
      otherQPRs.forEach((x) => {
        x.fillSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.fillSubscriptionFrequencyDays : null;
        x.chargeSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.chargeSubscriptionFrequencyDays : null;
        x.numberOfSubscriptionChargesRequired = this.selectedFrequency ? this.selectedFrequency.numberOfSubscriptionChargesRequired : null;
        x.isSubscription = this.isSubscription;
        q.qProductRxs.push(x);
      });
      console.log("q:", q);
      return q;
    },
    prev_click() {
      this.$emit("saveandnavigate", "-");
    },
    next_click(p: AffiliateProductRx) {
      if (this.$gtm && this.$gtm.enabled() && this.Content.GTMConfig) {
        this.$gtm.trackEvent({
          event: "Add Program",
          category: "Program Checkout",
          action: "click",
          label: this.currentQTP?.displayName,
          noninteraction: false, // Optional
        });
      }
      this.$emit("saveandnavigate", "+", this.getUpdatedData(p), this.fromCheckout);
    },
    handleSlideChange() {
      const slides = this.swiper.slides;
      slides.forEach((slide: any) => {
        slide.classList.remove("active-slide");
        slide.classList.add("inactive-slide");
      });
      const activeSlide = slides[this.swiper.activeIndex];
      if (activeSlide) {
        activeSlide.classList.add("active-slide");
        activeSlide.classList.remove("inactive-slide");
      }
      this.hideSwiperArrows();
    },
    selectRxType(type: string) {
      this.selectedRxType = type;
      Vue.nextTick(() => {
        this.swiper?.destroy();
        this.startSwiper();
      });
    },
    hideSwiperArrows() {
      const slides = this.swiper.slides;
      const activeSlide = slides[this.swiper.activeIndex];
      if (this.swiper.activeIndex === 0) {
        this.showSwiperArrowLeft = false;
      } else {
        this.showSwiperArrowLeft = true;
      }
      if (this.swiper.activeIndex === slides.length - 1) {
        this.showSwiperArrowRight = false;
      } else {
        this.showSwiperArrowRight = true;
      }
    },
    startSwiper() {
      if (this.Content.GetStartedVers == 2) {
        this.swiper = new Swiper(this.$refs.swiperContainer as HTMLBodyElement, {
          effect: "coverflow",
          initialSlide: 0,
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          slideToClickedSlide: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          on: {
            slideChange: this.handleSlideChange,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        Vue.nextTick(() => {
          this.handleSlideChange();
        });
      }
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slidePrevious() {
      this.swiper.slidePrev();
    },
  },
  components: { QuestWrapperComponent },
});
