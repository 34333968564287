
import { BCol, BContainer, BRow } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ScreenEnum } from "@/interfaces/ScreenEnum";
import { StatusIDEnum } from "@/interfaces/StatusIDEnum";
import store from "@/store/index";
import router from "@/router";
import moment from "moment";
import { SubscriptionFrequency } from "@/interfaces/SubscriptionFrequency";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";

export default Vue.extend({
  props: {
    outsidePromo: { type: String, default: null },
  },
  data() {
    return {
      placeholder: false,
      screen: 0 as number,
      selectedProductSubscriptionAvailable: false as boolean,
      selectedFrequency: null as SubscriptionFrequency | null,
      adjustedDoctorConsult: 0 as number,
      adjustedProductPrice: 0 as number,
      medTotal: 0 as number,
      totalPrice: 0 as number,
      paymentQuestType: 0 as number,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Payments: "getPayments",
      SignedIn: "getSignedIn",
      CurrentQuestType: "getCurrentQuestType",
      IsQuickLink: "getIsVerifiedQuickLink",
    }),
    q(): PaymentViewModel {
      return this.Payments[this.paymentQuestType];
    },
    underAge(): boolean {
      return moment().diff(moment(this.q.patient.dateOfBirth, "YYYY=MM-DD"), "years", true) < 18;
    },
  },
  async created() {
    this.paymentQuestType = this.CurrentQuestType;
    if (!this.paymentQuestType) {
      router.push("/"); // Got here without picking a condition
      return;
    }
    if (!this.SignedIn) {
      if (router.currentRoute.name != "/signin") router.push("/signin");
      return;
    }

    if (this.IsQuickLink) {
      this.setIsVerifiedQuickLink(false);
      console.log(`quick link cleared`);
    }
    this.init();
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setPaymentQuest: "setPaymentQuest",
      setPaymentPatient: "setPaymentPatient",
      setIsVerifiedQuickLink: "setIsVerifiedQuickLink",
    }),
    ensureScreen() {
      switch (router.currentRoute.name) {
        case "/payment/shippingpaymentinfo":
          this.setScreen(ScreenEnum.ShippingPaymentInfo, true);
          break;
        case "/payment/error":
          this.setScreen(ScreenEnum.Error, true);
          break;
        case "/payment/chooseshipping":
          this.setScreen(ScreenEnum.ChooseShipping, true);
          break;
        case "/payment/productreview":
          this.setScreen(ScreenEnum.ProductReview, true);
          break;
        case "/payment/refillcomplete":
          this.setScreen(ScreenEnum.RefillComplete, true);
          break;
        case "/payment/refillchargeconfirmation":
          this.setScreen(ScreenEnum.RefillChargeConfirmation, true);
          break;
        default:
          break;
      }
    },
    async init() {
      this.setLoading(true);
      this.ensureScreen();
      var arVM: AjaxReturnViewModel<PaymentViewModel> = await store.dispatch("GetPayment", { t: this.paymentQuestType });
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        this.setLoading(false);
        return;
      }
      var q: PaymentViewModel = arVM.data;
      if (!q || !q.quest) {
        router.push("/payment/error");
        this.setLoading(false);
        return;
      }
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID != StatusIDEnum.AwaitingPayment && q.quest.statusID != StatusIDEnum.PaymentFailed) {
        router.push("/patientportal");
        this.setLoading(false);
        return;
      }
      this.pageLoadNavigate();
      this.setLoading(false);
    },
    async navigate(dir: string, lastQuestionScreen?: number | null, forceScreenChange?: boolean | null, skipShipping?: boolean | null) {
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      switch (this.screen) {
        case ScreenEnum.ProductReview:
          if (dir === "+") {
            if (this.q.currentOTCCart != null && this.q.currentOTCCart.length > 0) this.setScreen(ScreenEnum.OTCCart);
            else this.setScreen(ScreenEnum.ShippingPaymentInfo);
          } else {
            if (this.q.productIsIntroductory || !this.q.selectedProductSubscriptionAvailable) {
              router.push("/patientportal");
            } else this.setScreen(ScreenEnum.ChooseShipping);
          }
          break;
        case ScreenEnum.ShippingPaymentInfo:
          if (dir === "+") this.setScreen(ScreenEnum.RefillChargeConfirmation);
          else {
            if (this.q.currentOTCCart != null && this.q.currentOTCCart.length > 0) this.setScreen(ScreenEnum.OTCCart);
            else {
              this.setScreen(ScreenEnum.ProductReview);
            }
          }
          break;
        case ScreenEnum.RefillChargeConfirmation:
          if (dir === "+") this.setScreen(ScreenEnum.RefillComplete);
          else this.setScreen(ScreenEnum.ShippingPaymentInfo);
          break;
        case ScreenEnum.RefillComplete:
          if (dir === "+") router.push("/patientportal");
          break;
        case ScreenEnum.OTCCart:
          if (dir === "+") this.setScreen(ScreenEnum.ShippingPaymentInfo);
          else this.setScreen(ScreenEnum.ProductReview);
          break;
        case ScreenEnum.ChooseShipping:
          if (dir === "+") this.setScreen(ScreenEnum.ProductReview);
          else router.push("/patientportal");
          break;
        default:
        // do nothing
      }
      this.setLoading(false);
    },
    pageLoadNavigate() {
      if (this.q.quest && this.q.quest.lastScreen && this.q.quest.lastScreen > ScreenEnum.BasicInfo) {
        if (this.q.quest.lastScreen === ScreenEnum.Questions) {
          this.setScreen(this.q.quest.lastScreen); //this.q.quest.questionsObj.lastQuestionScreen);
        } else {
          this.setScreen(this.q.quest.lastScreen);
        }
      } else {
        if (this.q.productIsIntroductory || !this.q.selectedProductSubscriptionAvailable) this.setScreen(ScreenEnum.ProductReview);
        else this.setScreen(ScreenEnum.ChooseShipping);
      }
    },
    async saveandnavigate(dir: string, data: any, data2: any, data3: any) {
      this.ensureScreen();
      var skipShipping = false;
      window.scrollTo(0, 0);
      this.setLoading(true);
      console.log(`saveandnavigate ${this.screen} ${dir}`);
      switch (this.screen) {
        case ScreenEnum.ProductReview:
          this.setPaymentQuest({ q: data, t: this.paymentQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ShippingPaymentInfo:
          this.setPaymentPatient({ p: data, q: data2, t: this.paymentQuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.RefillChargeConfirmation:
          if (dir === "+") {
            var result = await store.dispatch("SavePayment", { q: this.q.quest, t: this.paymentQuestType });
            if (result) this.navigate(dir);
            else this.setScreen(ScreenEnum.Error);
          } else this.navigate(dir);
          break;
        case ScreenEnum.RefillComplete:
          this.navigate(dir);
          break;
        case ScreenEnum.OTCCart:
          /*this.CurrentOTCCart = data;
          this.saveQuest(this.Quest, function () {
            this.saveOTCCart(this.CurrentOTCCart, function () {
              this.navigate(dir);
            });
          });*/
          this.setPaymentQuest({ q: this.q.quest, t: this.paymentQuestType });
          // TODO: need save otc cart
          this.navigate(dir);
          break;
        case ScreenEnum.ChooseShipping:
          this.setPaymentQuest({ q: data, t: this.paymentQuestType });
          this.navigate(dir);
          break;
        default:
        // do nothing
      }
    },
    setScreen(s: number | ScreenEnum, noPush?: boolean) {
      console.log(`setScreen ${s}`);
      if (s !== ScreenEnum.Questions && s === this.screen) return;
      if (this.q && this.q.quest) {
        this.q.quest.lastScreen = s;
      }
      this.screen = s;

      if (noPush) {
        //history.pushState({}, "", document.location.href.split("#")[0] + "#" + s + ":" + qs);
      } else {
        switch (s) {
          case ScreenEnum.ChooseShipping:
            router.push("/payment/chooseshipping");
            break;
          case ScreenEnum.ProductReview:
            router.push("/payment/productreview");
            break;
          case ScreenEnum.ShippingPaymentInfo:
            router.push("/payment/shippingpaymentinfo");
            break;
          case ScreenEnum.RefillChargeConfirmation:
            router.push("/payment/refillchargeconfirmation");
            break;
          case ScreenEnum.RefillComplete:
            router.push("/payment/refillcomplete");
            break;
          case ScreenEnum.Error:
            router.push("/payment/error");
            break;
          case ScreenEnum.NotYet:
            router.push("/payment/sorry");
            break;
          default:
            break;
        }
      }
      this.setLoading(false);
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
  },
});
