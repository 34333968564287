
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import moment from "moment";
import { BModal, BIcon } from "bootstrap-vue";
import { mapGetters, mapMutations } from "vuex";
import { number, object, string, date } from "yup";
import { VueTelInput } from "vue-tel-input";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { PropType } from "vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import store from "@/store/index";
import LottieAnimation from "@/components/LottieAnimationsComponent.vue";

export default mixins(CustomValidation).extend({
  name: "BasicInfoView",
  mixins: [CustomValidation],
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
  },
  data() {
    return {
      dobMonth: null as string | null,
      dobDay: null as string | null,
      dobYear: null as string | null,
      gender: null as string | null,
      state: null as string | null,
      phone: "" as string,
      formattedPhone: "" as string,
      invalidCode: false as boolean,
      newCodeSent: false as boolean,
      defaultErrorMessage: false as boolean,
      phoneIsValid: false as boolean,
      screen: 0,
      picture: "",
      schema: object().shape({
        gender: string().typeError("Gender is required.").required(),
        dobMonth: number().integer().typeError("Invalid Month.").min(0, "Invalid Month.").max(12, "Invalid Month.").required(),
        dobDay: number().integer().typeError("Invalid Day.").min(0, "Invalid Day.").max(31, "Invalid Day.").required(),
        dobYear: number().integer().typeError("Invalid Year.").min(1900, "Invalid Year.").max(3000, "Invalid Year.").required(),
        state: string().typeError("State is required.").required(),
        dateOfBirthValidation: date().typeError("Invalid Birthdate.").required(),
        phone: string()
          .typeError("Phone Number is required.")
          .matches(/^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/, "Invalid Phone Number.")
          .required("Phone Number is required."),
      }),
      currentSMSStep: 1 as number,
      firstdigit: "" as string | "",
      seconddigit: "" as string | "",
      thirddigit: "" as string | "",
      fourthdigit: "" as string | "",
      fifthdigit: "" as string | "",
      sixthdigit: "" as string | "",
      isLoading: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Affiliate: "getAffiliate",
    }),
    Patient() {
      return this.q.patient;
    },
    dateOfBirthValidation(): Date | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return moment(this.dateOfBirth, "YYYY-MM-DD").toDate();
      }
      return null;
    },
    dateOfBirth(): string | null {
      if (this.dobMonth !== null && this.dobDay !== null && this.dobYear !== null) {
        return this.dobYear + "-" + this.dobMonth + "-" + this.dobDay;
      }
      return null;
    },
  },
  created: function () {
    this.setupValidation(this.schema);
    this.importData(this.Patient);
    this.setLoading(false);
  },
  mounted() {
    window.onmessage = (e) => {
      if (!e.data || !e.data.messageType) return;
      switch (e.data.messageType) {
        case "UpdatePhoneNumber":
          this.closeSMSModal;
          break;
        case "ToggleSMS":
          this.toggleSMS(e.data.toggleSMSValue);
          break;
        default:
          break;
      }
    };
  },
  watch: {
    Patient: function (newVal: PatientViewModel | null) {
      this.importData(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    calcCode() {
      return this.firstdigit + this.seconddigit + this.thirddigit + this.fourthdigit + this.fifthdigit + this.sixthdigit;
    },
    dobDay_onKeyUp() {
      if (this.dobDay !== null && this.dobDay.length === 2) {
        (this.$refs.dobYear as HTMLElement).focus();
      }
    },
    dobMonth_onKeyUp() {
      if (this.dobMonth !== null && this.dobMonth.length === 2) {
        (this.$refs.dobDay as HTMLElement).focus();
      }
    },
    importData(newVal: PatientViewModel | null) {
      if (newVal == null) return;
      var mDob = null;
      if (newVal.dateOfBirth !== null && newVal.dateOfBirth.length > 0) {
        mDob = moment(newVal.dateOfBirth, "YYYY-MM-DD");
        this.dobMonth = (mDob.month() + 1).toString();
        this.dobDay = mDob.date().toString();
        this.dobYear = mDob.year().toString();
      }
      this.gender = newVal.gender;
      this.state = newVal.state;
      this.phone = newVal.phone;
    },
    getUpdatedData() {
      var p = Object.assign({}, this.Patient);
      p.dateOfBirth = this.dateOfBirth;
      p.gender = this.gender;
      p.homeState = this.state;
      p.state = this.state;
      p.phone = this.phone;
      return p;
    },
    phone_validate(e: { isValid: any }) {
      this.phoneIsValid = e.isValid;
      //if (this.phone) this.validate("phone");
    },
    async prev_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          console.log(this.getUpdatedData());
          this.$emit("saveandnavigate", "-", this.getUpdatedData());
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    async next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(async () => {
          if (this.$gtm && this.$gtm.enabled() && this.Content.GTMConfig) {
            window.dataLayer?.push({
              event: "basicinfo",
              phone: this.phone,
            });
          }
          this.errors = [];

          if (this.Affiliate.smsEnabled === true && !this.q.phoneNumberConfirmed) {
            this.showSMSVerifyModal();
            await store.dispatch("SavePatient", { p: this.getUpdatedData(), t: this.QuestType });
            await store.dispatch("GetSMSCode", { phone: this.phone });
            this.invalidCode = false;
            this.newCodeSent = false;
            this.defaultErrorMessage = false;

            return;
          }
          this.$emit("saveandnavigate", "+", this.getUpdatedData());
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    showSMSVerifyModal() {
      this.formatPhoneNumber(this.phone);
      this.$bvModal.show("modal-sms");
    },
    closeSMSModal() {
      const input = document.querySelector(".vti__input") as HTMLElement;

      this.$bvModal.hide("modal-sms");
      setTimeout(() => {
        if (input) {
          input.focus();
        }
      }, 1000);
    },
    async displayNextSMSStep() {
      if (!this.firstdigit || !this.seconddigit || !this.thirddigit || !this.fourthdigit || !this.fifthdigit || !this.sixthdigit) {
        this.invalidCode = true;
        return;
      } else {
        try {
          this.isLoading = true;
          const response = await store.dispatch("ValidateSMSCode", { code: this.calcCode() });

          if (response && response.success === true) {
            this.q.phoneNumberConfirmed = true;
            this.currentSMSStep++;
            setTimeout(() => {
              this.currentSMSStep = 3;
            }, 2500);
            this.isLoading = false;
          } else {
            this.invalidCode = true;
            this.newCodeSent = false;
            this.isLoading = false;
          }
        } catch (error) {
          console.error("Error validating SMS code: ", error);
          this.invalidCode = true; // Handle unexpected errors by setting invalidCode to true
          this.isLoading = false;
        }
      }
    },

    async resendCode() {
      try {
        this.setLoading(true);
        const response = await store.dispatch("GetSMSCode", { phone: this.phone });

        if (response && response.success === true) {
          this.invalidCode = false;
          this.newCodeSent = true;
          this.setLoading(false);
        } else {
          this.defaultErrorMessage = true;
          this.setLoading(false);
        }
      } catch (error) {
        console.error("Error sending SMS code: ", error);
        this.defaultErrorMessage = true;
      }
    },

    inputChange(e: Event) {
      e.preventDefault();
      const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const bspace = e.keyCode == 8 ? true : false;
      let validkey = true;
      if (!keys.includes(e.key) && e.keyCode != 8) {
        validkey = false;
      }
      var current = null;
      var target = null;
      switch (e.target) {
        case this.$refs.firstd:
          if (!bspace) {
            target = this.$refs.secondd;
          }
          current = this.$refs.firstd;
          break;
        case this.$refs.secondd:
          if (!bspace) {
            target = this.$refs.thirdd;
          } else {
            target = this.$refs.firstd;
          }
          current = this.$refs.secondd;
          break;
        case this.$refs.thirdd:
          if (!bspace) {
            target = this.$refs.fourthd;
          } else {
            target = this.$refs.secondd;
          }
          current = this.$refs.thirdd;
          break;
        case this.$refs.fourthd:
          if (!bspace) {
            target = this.$refs.fifthd;
          } else {
            target = this.$refs.thirdd;
          }
          current = this.$refs.fourthd;
          break;
        case this.$refs.fifthd:
          if (!bspace) {
            target = this.$refs.sixthd;
          } else {
            target = this.$refs.fourthd;
          }
          current = this.$refs.fifthd;
          break;
        case this.$refs.sixthd:
          if (bspace) {
            target = this.$refs.fifthd;
          }
          current = this.$refs.sixthd;
          break;
        default:
          break;
      }
      if (target && validkey && !bspace) {
        target.focus();
        target.select();
        return;
      }
      if (e.key == " " && current && !bspace) {
        current.value = "";
      }
      if (current && target && bspace && current.value == "") {
        target.focus();
        target.select();
      }
      const code = this.calcCode();
      if (code.length == 6) {
        this.$emit("codeChange", code);
      }
    },
    inputPaste(e: Event) {
      e.preventDefault();
      const data = e.clipboardData.getData("text");
      if (data.length == 6) {
        this.firstdigit = data[0];
        this.seconddigit = data[1];
        this.thirddigit = data[2];
        this.fourthdigit = data[3];
        this.fifthdigit = data[4];
        this.sixthdigit = data[5];

        const code = this.calcCode();
        this.$emit("codeChange", code);
      }
    },
    formatPhoneNumber(phone: string) {
      const cleaned = phone.replace(/\D/g, "");

      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        this.formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
      } else {
        return phone;
      }
    },
    async toggleSMS(option: boolean) {
      this.setLoading(true);
      await store.dispatch("ToggleSMS", { enabled: option });
      this.$emit("saveandnavigate", "+", this.getUpdatedData());
    },
    async resetPhoneNumberConfirmed() {
      this.setLoading(true);
      this.q.phoneNumberConfirmed = false;
      await store.dispatch("ResetPhoneNumberConfirmed");
      this.setLoading(false);
    },
  },

  components: { VueTelInput, QuestWrapperComponent, BModal, BIcon, LottieAnimation },
});
