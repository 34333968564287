
import Vue from "vue";
import { mapGetters } from "vuex";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import { BButton, BFormInput, BImg, BFormSelect } from "bootstrap-vue";
import { PropType } from "vue";

export default Vue.extend({
  props: {
    item: {
      type: Object as PropType<ShoppingCartItem>,
      default: null,
    },
  },
  data() {
    return {
      cartItem: {} as ShoppingCartItem,
      deleteFlag: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      getOTCProducts: "getOTCProducts",
    }),
    OTCProducts(): OTCProductViewModel[] {
      return this.getOTCProducts;
    },
    Product(): OTCProductViewModel {
      const i = this.OTCProducts.findIndex((p: OTCProductViewModel) => p.id == this.item.productID && p.productType == this.item.productTypeID);
      return this.OTCProducts[i];
    },
  },
  created() {
    this.importData(this.item);
  },
  watch: {
    item(newVal) {
      this.importData(newVal);
    },
  },
  methods: {
    importData(newVal: ShoppingCartItem) {
      this.cartItem = newVal;
      this.deleteFlag = false;
    },
    deleteItem() {
      this.$emit("itemRemoved", this.cartItem);
    },
    qtyChanged() {
      this.$emit("qtyChanged", this.cartItem, this.cartItem.qty);
    },
    itemDeleteSelected() {
      this.$emit("itemDeleteSelected", this.cartItem.id, this.deleteFlag);
    },
  },
  components: {
    BFormInput,
    //BImg,
    BFormSelect,
    BButton,
  },
});
