
import lottie from "lottie-web";

export default {
  name: "LottieAnimation",
  props: {
    animationPath: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "200px",
    },
    width: {
      type: String,
      default: "200px",
    },
  },
  mounted() {
    this.initLottie();
  },
  methods: {
    initLottie() {
      lottie.loadAnimation({
        container: this.$refs.lottieContainer as HTMLElement,
        renderer: "svg",
        loop: this.loop,
        autoplay: this.autoplay,
        path: this.animationPath,
      });
    },
  },
  beforeDestroy() {
    lottie.destroy();
  },
};
