
import { BFormInput, BFormSelect, BButton, BContainer } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import store from "../store/index";
import CartViewItemComponent from "@/components/CartViewItemComponent.vue";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import { ProductPrice } from "@/interfaces/ProductPrice";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";

export default Vue.extend({
  data() {
    return {
      deletedItems: [],
    };
  },
  computed: {
    ...mapGetters({
      ShoppingCart: "getShoppingCart",
      SignedIn: "getSignedIn",
      getOTCProducts: "getOTCProducts",
    }),
    OTCProducts(): OTCProductViewModel[] {
      return this.getOTCProducts;
    },
    totalCost(): number {
      let sum = 0;
      const c = this.ShoppingCart?.items?.reduce((sum: number, item: ShoppingCartItem) => sum + item.subTotal, 0);
      return !c ? 0.0 : c.toFixed(2);
    },
  },
  methods: {
    async mounted() {
      this.ShoppingCart.shown = false;
      this.ShoppingCart.visible = false;
    },
    async deleteSelected() {
      this.ShoppingCart.items.forEach((i: ShoppingCartItem) => {
        if (this.deletedItems.includes(i.id)) {
          this.deleteItem(i);
        }
      });
      this.deletedItems = [];
    },
    onItemDeleteSelected(id: number, deleteflag: boolean) {
      if (!deleteflag) {
        console.log(id + " flagged for deletion");
        this.deletedItems.push(id);
      } else {
        this.deletedItems = this.deletedItems.filter((i) => i != id);
        console.log(id + " removed from deletion");
      }
    },
    async deleteItem(item: ShoppingCartItem) {
      item.active = false;
      if (this.SignedIn) {
        const rsp = await store.dispatch("SaveShoppingCartItem", [item]);
        if (rsp) {
          //this.ShoppingCart.items = this.ShoppingCart.items.filter((i) => item.sid != i.sid);
          store.commit("setShoppingCart", rsp.cart);
          return true;
        }
        return false;
      } else {
        this.ShoppingCart.items = this.ShoppingCart.items.filter((i) => item.id != i.id);
        store.commit("setShoppingCart", this.ShoppingCart);
        return true;
      }
    },
    async itemQtyChanged(item: ShoppingCartItem, qty: number) {
      if (!item || !this.ShoppingCart || !this.ShoppingCart.items) {
        return;
      }
      const index = this.ShoppingCart.items.findIndex((i: ShoppingCartItem) => i.id == item.id);
      if (index < 0) {
        console.log("invalid cart item");
        return;
      }
      const pqty = this.ShoppingCart.items[index].qty;
      const pi = this.OTCProducts.findIndex((i: OTCProductViewModel) => i.id == item.productID && i.productType == item.productTypeID);
      if (pi < 0) {
        console.log("invalid product");
        return;
      }
      const product = this.OTCProducts[pi];
      const price = product.prices.findIndex((i: ProductPrice) => qty >= i.minQty && qty <= i.maxQty);
      if (price < 0) {
        item.qty = pqty;
        console.log("invalid price");
        return;
      }
      const basePrice: number = qty * product.prices[price].pricePerUnit;
      const p = basePrice * product.prices[price].markupPercentage;

      item.qty = parseInt(qty);
      item.affiliateMarkupPercent = product.prices[price].markupPercentage;
      //item.pricePerUnit = product.prices[price].pricePerUnit;
      item.subTotal = p;
      this.ShoppingCart.items[index].qty = parseInt(qty);
      this.ShoppingCart.items[index].markup = product.prices[price].markupPercentage;
      this.ShoppingCart.items[index].pricePerUnit = product.prices[price].pricePerUnit;
      this.ShoppingCart.items[index].price = p;
      if (this.SignedIn) {
        const rsp = await store.dispatch("SaveShoppingCartItem", [item]);
        if (rsp) {
          store.commit("setShoppingCart", rsp.cart);
        }
        return;
      } else {
        store.commit("setShoppingCart", this.ShoppingCart);
      }
    },
  },
  components: {
    CartViewItemComponent,
  },
});
